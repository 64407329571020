<template>
  <v-card flat :class="['rounded-0',`card-bg-step-${step}`]">
    <v-container 
      :class="
        [
          $vuetify.breakpoint.mobile ? 'pa-0' : '',
        ]">
      <v-row >
        <v-col cols="12" class="pa-0">
          <v-card flat color="white" class="rounded-0 pa-10">
            <v-container fluid>
              <v-row>
                <template v-if="withItems.length <= 0">
                  <v-col cols="12" align-self="center" class="text-left">
                    <p class="chivo black--text" v-html="description" />
                  </v-col>
                </template>
                <template v-if="withItems.length > 0">
                  <v-col cols="12" lg="5" offset-lg="1" align-self="center" class="text-left">


                    <template v-for="(item, i) in withItems">
                      <p class="chivo d-block black--text" v-if="item.type == 'description'" :key="`description${step}-${i}`">{{ item.description }}</p>
                      <v-checkbox
                        readonly
                        light
                        color="turquesa"
                        :key="`checkbox${step}-${i}`" 
                        v-if="item.type == 'checkbox'"
                        hide-details
                        class="pa-0"
                        v-model="item.checked">
                        <template v-slot:label>
                          <v-tooltip max-width="200px" color="azul2" bottom transition="slide-y-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-on="on" v-bind="attrs" class="chivo azul4--text font-weight-bold">{{item.label}}</span>
                            </template>
                            Las tareas se marcarán automáticamente cuando se guarden en la BlockChain
                          </v-tooltip>
                        </template>
                      </v-checkbox>

                    </template>


                  </v-col>
                  <v-col cols="12" lg="5" align-self="center" class="text-left">
                    <p class="chivo black--text" v-html="description" />
                  </v-col>
                </template>
              </v-row>

              <v-row v-if="moreDataWithNewsButton" class="text-left">
                <v-col cols="12" lg="6" offset-lg="6">
                  <news-button no-block />
                </v-col>
              </v-row>

              <v-row v-if="moreDataWithSaberMasButton">
                <v-col cols="12">
                  <saber-mas-button no-block />
                </v-col>
              </v-row>

            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'ExpansionContent',
  components: {
    NewsButton: () => import('@/views/components/NewsButton'),
    SaberMasButton: () => import('@/views/components/SaberMasButton')
  },
  props: {
    description: {
      type: String,
      default: ""
    },
    step: {
      type: String,
      default: '00'
    },
    withItems: {
      type: Array,
      default: () => []
    },
    moreDataWithNewsButton: {
      type: Boolean,
      default: false
    },
    moreDataWithSaberMasButton: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>